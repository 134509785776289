.container-portfolio{
    height: auto;
    background-color: var(--bg-black);
    padding-bottom: 40px;
}

.container-portfolio h2{
    color: #fff;
    padding-top: 20px;
    font-weight: 600;
}

.client-button {
    color: #fff;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    border-radius: 5px;
    max-width: 500px;
    font-size: 25px;
    background-color: var(--btn-icons);
    cursor: pointer;
    text-decoration: none;
}

.client-button:hover{
    color: #fff;
}

@media (max-width: 485px) {
    .client-button{
        font-size: 20px;
    }
}

@media (max-width: 390px) {
    .client-button{
        font-size: 15px;
    }
}

@media (max-width: 300px) {
    .client-button{
        font-size: 12px;
    }
}


.logo-carousel-container {
    max-width: 100%;
    width: 1050px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0 auto;
  }
  
  .logo-item {
    text-align: center;
  }
  
  .logo-item img {
    margin: 0 auto;
  }
  
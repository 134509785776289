.benefits {
    width: 100%;
    height: auto;
    background-image: url("https://img.lovepik.com/background/20211022/large/lovepik-sky-clouds-background-image_401746897.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

}

.benefits::after {
    content: "";
    background-color: rgb(245, 235, 230, 0.9);
    /* Cor preta com 50% de opacidade */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

}

.content-benefits {
    position: relative;
    z-index: 2;

}

.content-work{
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding-top: 30px;
    width: 100%;
    max-width: 1050px;
    justify-content: space-between;
    text-align: center;
    /* justify-content: center; */
}

.benefits h3 {
    text-align: left;
    padding-top: 20px;
    padding-left: 20px;
    font-weight:bolder;
    font-size:25px;
    color: #3E3C3A;
    margin: 10px;
}

.content-work img{
    width: 100%;
    max-width: 650px;
}

.content-work-reverse img{
    max-width: 500px;
    width: 100%;
    border-top-left-radius: 40%;
    border-bottom-left-radius: 40%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1050px) {
    .content-work{
        flex-direction: column;
    }

    .content-work-reverse{
        flex-direction: column-reverse;
    }
}

@media (max-width: 370px) {
    .benefits h3{
        font-size: 15px;
    }
}


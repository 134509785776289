.notFound {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    color: #fff;
    background-color: #262626;
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
}
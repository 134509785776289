.container-depositions {
    width: 100%;
    height: auto;
    background-color: var(--bg-black);
    padding-top: 40px;
    padding-bottom: 40px;
}

.container-depositions h2 {
    color: #fff;
    padding-top: 20px;
    font-weight: 500;
}

.container-quote-map {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
}

.margin-button {
    padding-left: 40px;
    padding-right: 40px;
}

@media (max-width: 1050px) {
    .container-quote-map {
        flex-direction: column;
    }
}

@media (max-width: 360px) {
    .deposition-carousel-container {
        padding: 20px;
    }
}

.deposition-carousel-container {
    max-width: 100%;
    width: 1050px;
    padding-top: 20px;
    padding-bottom: 40px;
    margin: 0 auto;
}

.deposition-item {
    text-align: center;
}

.deposition-item img {
    margin: 0 auto;
}

.logo {
    width: 100%;
    max-width: 230px;
    height: auto;
}
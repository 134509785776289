#my-navbar {
    background-color: #262626;
    height: auto;
}

.container-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    max-width: 1050px;
    width: 100%;
}

.nav-logo {
    width: 230px;
    height: 35px;
}

.social-medias {
    display: flex;
    flex-direction: column;
}

.icon-contact {
    margin-left: 10px;
    color: var(--btn-icons);
    text-decoration: none;
}

.icon-contact :hover{
    color: var(--btn-icons);
}

.container-button-links {
    display: flex;
    flex-direction: row;
}

@media (max-width: 991px) {
    .container-button-links {
        flex-direction: column;
    }

    .social-medias {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 10px;
    }

    .container-navbar {
        display: block;
    }

    .navbar-collapse {
        margin: 0;
    }

    .nav-logo {
        margin: 0;
    }
}
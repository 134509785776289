.container-contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    background-color: #333333;
    height: auto;
    padding: 30px;
    color: #fff;
}

.content-contacts {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
}

.content-social-medias{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
}

.content-icons {
    margin: 0 auto;
}

.content-icons a {
    text-decoration: none;
    color: var(--btn-icons);
    margin-left: 10px;
    font-size: 35px;
}

.content-icons a:hover {
    color: var(--btn-icons)
}


.content-phone-email {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
}

#nome,
#email,
#mensagem {
    display: flex;
    background-color: #333333;
    color: #fff;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}

.form-control {
    resize: none;
}


.form-control:focus,
.form-control:focus-within {
    border-color: var(--btn-icons);
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.form-control:active {
    border-color: var(--btn-icons);
    box-shadow: none;
}

.form-control::placeholder {
    color: #fff;
    font-size: 14px;
}

#sendMessage {
    color: #fff;
    font-weight: 600;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 15px;
    background-color: var(--btn-icons);
}


@media (max-width: 1050px) {
    .content-contacts {
        flex-direction: column;
    }
}
.solutionsGrid {
    width: 100%;
    height: auto;
    background-image: url("https://img.lovepik.com/background/20211022/large/lovepik-sky-clouds-background-image_401746897.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.solutionsGrid::after {
    content: "";
    background-color: rgb(245, 235, 230, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.content-solutions {
    position: relative;
    z-index: 2;
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
}

.cXYUgm {
    justify-content: center;
    padding-bottom: 40px;
}

.fPimMH {
    justify-content: center;
}


.solutionsGrid h3 {
    padding-top: 50px;
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
}

.solutions-btn-grid {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.btn-style {
    margin: 5px;
    width: 140px;
    height: 40px;
    border: 1px solid black;
    border-color: #262626;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent;
    
}

.active {
    color: #fff;
    border: none;
    background-color: var(--btn-icons);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.image-solution {
    padding-top: 20px;
}

.carroussel-container {
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 50px !important;
}

.solution-title {
    margin-top: -20px;
    font-size: 14px;
    font-weight: 700
}

.solution-local {
    font-size: 13px;
}
.slider-item {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    margin-top: 55px;
    z-index: 2;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
}

.text-overlay img {
    width: 100%;
    max-width: 500px;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.App {
  text-align: center;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

body,
html {
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

.diplay-none {
  display: none;
}

:root {
  --btn-icons: #73BD18;
  --bg-black: #000000;
  --text-v1: #ffffff;
  --text-v2: #000000;
  --text-v3: #000000;

}
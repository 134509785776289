.container-partners {
    width: 100%;
    height: auto;
    background-image: url("https://img.lovepik.com/background/20211022/large/lovepik-sky-clouds-background-image_401746897.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}

.container-partners::after {
    content: "";
    background-color: rgb(245, 235, 230, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.container-partners h2{
    color: #3C3A39;
    font-size: 35px;
    font-weight: 600;
}

.content-partners {
    position: relative;
    z-index: 2;
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
}

.logo-carousel-container {
    max-width: 100%;
    width: 1050px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0 auto;
  }
  
  .logo-item {
    text-align: center;
  }
  
  .logo-item img {
    margin: 0 auto;
  }
  

  .carousel-partners .slick-next::before,
  .carousel-partners .slick-prev::before{
  color: black;
 }
.about {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    /* background-color: #262626; */
    background-image: linear-gradient(to right, #1B1B1B, #2D2D2D 80%,  #1B1B1B);
    text-align: center;
    align-content: center;
    align-items: center;
    /* padding-top: 50px; */
}

.about h2 {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
}

.quotation-marks {
    margin-top: 50px;
    width: 50px;
}

.quote-container {
    text-align: center;
    line-height: normal;
    max-width: 700px;
    margin: 25px;
    color: #fff;
}

.quote {
    padding: 30px;
}

.author {
    color: #70B81A
}

.profession {
    color: #fff
}



.results {
    /* margin-top: 40px;
    margin-bottom: 40px; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

}

.result {
    display: flex;
    flex-direction: row;

}

.data {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: #fff;
    margin: 5px;
    max-width: 155px;

}

/* .data img {
    width: 107px;
    height: 102px;
    align-self: center
} */

/* SVGS */
.data img {
    width: 80px;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
    padding-bottom: 10px;
}

@media (max-width: 555px) {
    .result {
        flex-direction: column;
    }

    .results {
        flex-direction: column;
    }

}



/* @media (max-width: 990px) {

    .results {
        width: 50%;
        flex-direction: column;
    }

    .data{
        padding: 10px;
    }

} */

/* @media (max-width: 834px) {
    .result {
        flex-direction: column;
    }

    .results {
        flex-direction: column;
    }

} */
.numberPoints {
    max-width: 40px;
    max-height: 40px;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    margin: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.numberPoints span {
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
}

.container-step {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
}

.step{
    width: 100%;
    font-size: 15px;
    font-weight: 500;
}

@media (max-width: 370px) {
    .numberPoints{
        width: 80%;
        height: 80%;
    }

    .numberPoints span{
        font-size: 15px;
    }
    
    .step{
        font-size: 13px;
        padding: 10px;
        max-width: 50px;
    }
}